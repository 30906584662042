import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import humanize from "humanize";
import { Box, Grid, Typography } from "@material-ui/core";

import clsx from "clsx";

import { connect } from "react-redux";

import { useStyles } from "./App";

import { CurrencyDisplay, CategoryAvatar } from "./DisplayElements";
import { AlternativeAccountTable } from "./Accounts";
import { push } from "connected-react-router";

import Chart from "./Chart";

// Prism.highlightAll();

function Summary({
  category,
  categories,
  categorySummary,
  currency,
  snapshots,
}) {
  const classes = useStyles();

  const current_balance = categorySummary
    ? categorySummary.filter((c) => c.code === currency)[0].amount
    : null;
  const previousSample =
    snapshots && snapshots.length
      ? snapshots[snapshots.length - 1].balances.filter(
          (c) => c.code === currency,
        )[0].amount
      : null;

  return (
    <>
      <div className={classes.accountDetailsHeader}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          className={classes.flexTitle}
        >
          <CategoryAvatar allCategories={categories} category={category} />
          &nbsp;{category}
        </Typography>
      </div>
      {categorySummary ? (
        <CurrencyDisplay
          displayZero
          component="p"
          variant="h4"
          code={currency}
          amount={current_balance}
        />
      ) : (
        <CircularProgress />
      )}

      <Typography color="textSecondary" className={classes.depositContext}>
        on {new Date().toDateString()}
      </Typography>
      {previousSample && categorySummary ? (
        <>
          <CurrencyDisplay
            displayZero
            delta
            component="p"
            variant="h6"
            code={currency}
            amount={current_balance - previousSample}
          />
          <Typography color="textSecondary" className={classes.depositContext}>
            since{" "}
            {humanize.relativeTime(
              Date.parse(snapshots[snapshots.length - 1].timestamp) / 1000,
            )}
          </Typography>
        </>
      ) : null}
    </>
  );
}

function CategoryDetails(props) {
  const {
    category,
    categories,
    categorySnapshots,
    activeCurrency,
    categorySummary,
  } = props;
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Summary
              category={category}
              categories={categories}
              categorySummary={categorySummary}
              currency={activeCurrency}
              snapshots={categorySnapshots}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.unpaddedPaper}>
            {categorySnapshots && (
              <Chart snapshots={categorySnapshots} currency={activeCurrency} />
            )}
            {!categorySnapshots && (
              <Box className={classes.centerPaper}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AlternativeAccountTable {...props} />
        </Grid>
      </Grid>
    </Container>
  );
}

function mergeBalances(oldBalances, newBalances) {
  if (!oldBalances) return newBalances;

  const balances = {};
  oldBalances.forEach(({ code, amount }) => {
    balances[code] = (balances[code] || 0) + parseFloat(amount);
  });
  newBalances.forEach(({ code, amount }) => {
    balances[code] = (balances[code] || 0) + parseFloat(amount);
  });
  return Object.entries(balances).map(([code, amount]) => ({
    code,
    amount: amount.toString(),
  }));
}

function mergeSnapshots(snapshotsDatas) {
  const lookup = {};
  snapshotsDatas.forEach((snapshotData) => {
    const multiSnapshotPerDayFilter = new Set();
    snapshotData.forEach((snapshot) => {
      const dateOnly = snapshot.timestamp.substring(0, 10);
      if (!multiSnapshotPerDayFilter.has(dateOnly)) {
        lookup[dateOnly] = mergeBalances(lookup[dateOnly], snapshot.balances);
      }
      multiSnapshotPerDayFilter.add(dateOnly);
    });
  });

  return Object.entries(lookup)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([date, balances]) => ({
      timestamp: date,
      balances: balances,
    }));
}

function mapStateToProps(state, { match }) {
  const { category } = match.params;

  let accounts = null;
  if (state.accounts.accountData) {
    accounts = Object.values(state.accounts.accountData).filter(
      (r) => r.category === category,
    );
  }

  let mergedSnapshots = null;
  if (state.snapshots.snapshotsData) {
    mergedSnapshots = mergeSnapshots(
      accounts
        .filter((r) => !r.hide_from_global)
        .map(
          ({ account_id }) =>
            state.snapshots.snapshotsData[`account:${account_id}`],
        ),
    );
  }

  return {
    category,
    accountData: accounts,
    categorySnapshots: mergedSnapshots,
    snapshots: state.snapshots.snapshotsData,
    accountSummary: state.tallies && state.tallies.accountSummary,
    categorySummary: state.tallies && state.tallies.categorySummary[category],
    categories: state.tallies && Object.keys(state.tallies.categorySummary),
    activeCurrency: state.activeCurrency,
  };
}

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  dispatchAsync: (action) =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject });
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetails);
