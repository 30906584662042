import { Auth } from "../lib/auth";

const baseUri = process.env.REACT_APP_HORIAMI_BACKEND_URI;

export class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnauthorizedError";
  }
}

async function getErrorMessage(response) {
  try {
    const responseJson = await response.json();
    if (typeof responseJson.detail === "string") {
      return responseJson.detail;
    }
  } catch {
    // eslint: ignore
  }
  return response.statusText;
}

async function handleUnauthorized(response) {
  if (response.status === 401) {
    throw new UnauthorizedError(await getErrorMessage(response));
  }
  return response;
}

async function handleErrors(response) {
  if (!response.ok) {
    if (response.status === 401) {
      throw new UnauthorizedError(await getErrorMessage(response));
    } else {
      throw new Error(await getErrorMessage(response));
    }
  }
  return response;
}

export async function login(
  username,
  password,
  recaptchaToken,
  scope = "read write",
) {
  const formData = new FormData();
  formData.append("grant_type", "password");
  formData.append("scope", scope);
  formData.append("username", username);
  formData.append("password", password);
  formData.append("recaptcha_token", recaptchaToken);

  const response = await fetch(`${baseUri}/token`, {
    method: "POST",
    body: formData,
  })
    .then(handleErrors)
    .then((r) => r.json());

  return response.access_token;
}

export async function handleAddAccount(account) {
  const token = Auth.getJwtToken();
  const addedAccount = await fetch(`${baseUri}/users/me/accounts`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(account),
  })
    .then(handleErrors)
    .then((r) => r.json());
  return addedAccount;
}

export async function handleUserImport(data) {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/users/me/import`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(handleErrors);
}

export async function handleUserReset() {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/users/me/reset`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then(handleErrors);
}

export async function handleUserExport() {
  const token = Auth.getJwtToken();
  const userExport = await fetch(`${baseUri}/users/me/export`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(handleErrors)
    .then((r) => r.blob());

  return userExport;
}

export async function handleRemoveAccount(account) {
  const token = Auth.getJwtToken();
  const removedAccount = await fetch(
    `${baseUri}/users/me/accounts/${account.account_id}`,
    { method: "DELETE", headers: { Authorization: `Bearer ${token}` } },
  )
    .then(handleErrors)
    .then((r) => r.json());
  return removedAccount;
}

export async function handleUpdateAccount(account) {
  const token = Auth.getJwtToken();
  const updatedAccount = await fetch(
    `${baseUri}/users/me/accounts/${account.account_id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(account),
    },
  )
    .then(handleErrors)
    .then((r) => r.json());
  return updatedAccount;
}

export async function handleUpdatePreferences(preferences) {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/users/me/preferences`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(preferences),
  })
    .then(handleErrors)
    .then((r) => r.json());
  return preferences;
}

export async function handleUpdateRates(request) {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/rates/codes`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  }).then(handleErrors);
}

export async function handleRefreshRates() {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/rates/refresh`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then(handleErrors);
}

export async function handleDeleteRate(id) {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/rates/${encodeURIComponent(id)}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then(handleErrors);
}

export async function handleChangePassword(oldPassword, newPassword) {
  const token = Auth.getJwtToken();
  await fetch(`${baseUri}/users/me/change_password`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
    }),
  })
    .then(handleErrors)
    .then((r) => r.json());
}

export async function handleFetchAccounts() {
  const token = Auth.getJwtToken();
  const accountData = await fetch(`${baseUri}/users/me/accounts`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(handleErrors)
    .then((r) => r.json());
  return accountData;
}

export async function handleFetchRates() {
  const token = Auth.getJwtToken();
  const ratesData = await fetch(`${baseUri}/rates`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(handleErrors)
    .then((r) => r.json());
  return ratesData;
}

export async function handleFetchUser() {
  const token = Auth.getJwtToken();
  const accountData = await fetch(`${baseUri}/users/me`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(handleErrors)
    .then((r) => r.json());
  return accountData;
}

export async function handleFetchAccount(accountId) {
  const token = Auth.getJwtToken();
  const response = await fetch(`${baseUri}/users/me/accounts/${accountId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  }).then(handleUnauthorized);

  if (response.status === 404) return null;

  const accountData = await response.json();
  return accountData;
}

export async function handleFetchSnapshots(accountData, accountIds = null) {
  const token = Auth.getJwtToken();
  const results = await Promise.all(
    [
      ...accountData
        .filter(
          (account) => !accountIds || accountIds.includes(account.account_id),
        )
        .map((account) => `account:${account.account_id}`),
      "global",
    ].map((snapshot_key) =>
      fetch(`${baseUri}/users/me/snapshots/${snapshot_key}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(handleErrors)
        .then((r) => r.json())
        .then((r) => ({ [snapshot_key]: r })),
    ),
  );

  const snapshots = Object.assign({}, ...results);
  return snapshots;
}

export async function handleFetchTallies(accountData, accountIds = null) {
  const token = Auth.getJwtToken();
  const categories = [...new Set(accountData.map((b) => b.category))];

  const codes = [
    ...new Set(
      accountData.map((b) => b.balances.map((balance) => balance.code)).flat(),
    ),
  ];

  const userTotals = Object.entries(
    accountData
      .filter((r) => !r.hide_from_global)
      .map((r) => r.balances)
      .flat()
      .reduce((balances, balance) => {
        balances[balance.code] =
          (balances[balance.code] || 0) + parseFloat(balance.amount);
        return balances;
      }, {}),
  ).map((v) => ({ code: v[0], amount: v[1] }));

  const categoryTotals = Object.fromEntries(
    categories.map((category) => [
      `category:${category}`,
      Object.entries(
        accountData
          .filter((r) => r.category === category)
          .filter((r) => !r.hide_from_global)
          .map((r) => r.balances)
          .flat()
          .reduce((balances, balance) => {
            balances[balance.code] =
              (balances[balance.code] || 0) + parseFloat(balance.amount);
            return balances;
          }, {}),
      ).map((v) => ({ code: v[0], amount: v[1] })),
    ]),
  );

  const codeTotals = Object.fromEntries(
    codes.map((code) => [
      `code:${code}`,
      Object.entries(
        accountData
          .map((r) => r.balances.filter((balance) => balance.code === code))
          .flat()
          .reduce((balances, balance) => {
            balances[balance.code] =
              (balances[balance.code] || 0) + parseFloat(balance.amount);
            return balances;
          }, {}),
      ).map((v) => ({ code: v[0], amount: v[1] })),
    ]),
  );

  const accountCodeTotals = Object.fromEntries(
    Object.values(accountData)
      .map((r) =>
        r.balances.map((balance, i) => [
          `account:${r.account_id}:code:${i}`,
          [
            {
              code: balance.code,
              amount: balance.amount,
            },
          ],
        ]),
      )
      .flat(),
  );

  const accountTotals = Object.fromEntries(
    accountData
      .filter(
        (account) => !accountIds || accountIds.includes(account.account_id),
      )
      .map((account) => [`account:${account.account_id}`, account.balances]),
  );

  const request = {
    balances: {
      ...categoryTotals,
      ...accountTotals,
      ...codeTotals,
      ...accountCodeTotals,
      __global__: userTotals,
    },
    target_codes: ["EUR", "USD", "GBP"],
  };
  const tallies = await fetch(`${baseUri}/rates/calculate`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(handleErrors)
    .then((r) => r.json());

  const categorySummary = Object.fromEntries(
    categories.map((category) => [category, tallies[`category:${category}`]]),
  );

  const codeSummary = Object.fromEntries(
    codes.map((code) => [code, tallies[`code:${code}`]]),
  );

  const accountSummary = Object.fromEntries(
    accountData
      .filter(
        (account) => !accountIds || accountIds.includes(account.account_id),
      )
      .map((account) => [
        account.account_id,
        tallies[`account:${account.account_id}`],
      ]),
  );

  const accountCodeSummary = Object.assign(
    {},
    ...accountData.map((account) => ({
      [account.account_id]: account.balances.map(
        (_, i) => tallies[`account:${account.account_id}:code:${i}`],
      ),
    })),
  );

  const globalSummary = tallies.__global__;

  return {
    accountSummary,
    globalSummary,
    categorySummary,
    codeSummary,
    accountCodeSummary,
  };
}
