import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";

function QuickEditDialog({ open, onUpdateBalance, onClose, account, balance }) {
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    setValue(balance?.amount);
  }, [balance]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Edit {balance?.code} balance for {account?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new balance for {balance?.code} currency for account{" "}
          {account?.title}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="balance"
          label="New balance"
          type="number"
          fullWidth
          onChange={(evt) => setValue(evt.target.value)}
          value={value || ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isNaN(parseFloat(value))}
          onClick={() => onUpdateBalance(balance, value)}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  account: state.accounts.accountData?.[state.quickEdit.accountId],
  balance: state.quickEdit.balance,
  open: state.quickEdit.open,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch({ type: "QUICKEDIT_CLOSE" }),
  onUpdateBalance: (balance, newAmount) =>
    dispatch({ type: "QUICKEDIT_UPDATE_BALANCE", balance, newAmount }),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickEditDialog);
