import React from "react";
import "./App.css";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";

import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import Accounts from "./Accounts";
import Admin from "./Admin";
import Settings from "./Settings";
import AccountDetails from "./AccountDetails";
import CategoryDetails from "./CategoryDetails";
import CurrencyPicker from "./CurrencyPicker";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import Navigation from "./Navigation";
import QuickEditDialog from "./QuickEditDialog";

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  hiddenInput: {
    display: "none",
  },
  input: {
    width: 200,
  },
  currencyPicker: {
    backgroundColor: theme.palette.background.paper,
  },
  accountList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //  width: `calc(100% - ${drawerWidth}px)`,
    // },
    zIndex: theme.zIndex.drawer + 1,
    ...(process.env.NODE_ENV === "development"
      ? { backgroundColor: "#f37351" }
      : {}),
  },
  appBarSpacer: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    fontFamily: "dosis",
    fontWeight: "bold",
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
  },
  flexTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  graphControlsStart: {
    flexGrow: 1,
  },
  graphControls: {
    display: "flex",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.type,
    justifyContent: "flex-end",
  },
  graphContainer: {
    padding: theme.spacing(2),
  },
  accountDetailsHeader: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  unpaddedPaper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  centerPaper: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  fixedHeight: {
    height: 240,
  },
  paperContents: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100,
  },
  depositContext: {
    flex: 1,
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function App({ drawerOpen, setDrawerOpen }) {
  const classes = useStyles();

  const handleDrawerToggle = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.logo} variant="h5" noWrap>
              horiami{process.env.NODE_ENV === "development" ? " (dev)" : ""}
            </Typography>
            <CurrencyPicker />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="Mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <SwipeableDrawer
              variant="temporary"
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              onOpen={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Navigation />
            </SwipeableDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Navigation />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              path="/accounts/:account_id"
              render={(props) => <AccountDetails {...props} />}
            />
            <Route
              path="/categories/:category"
              render={(props) => <CategoryDetails {...props} />}
            />
            <Route
              path="/accounts"
              render={(props) => <Accounts {...props} />}
            />
            <Route
              path="/settings"
              render={(props) => <Settings {...props} />}
            />
            <Route path="/admin" render={(props) => <Admin {...props} />} />
          </Switch>
        </main>
        <QuickEditDialog />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  drawerOpen: state.app.drawerOpen,
});

const mapDispatchToProps = (dispatch) => ({
  setDrawerOpen: (drawerOpen) =>
    dispatch({ type: drawerOpen ? "DRAWER_OPEN" : "DRAWER_CLOSE" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
