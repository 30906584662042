import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import "chartkick/chart.js";
import clsx from "clsx";
import humanize from "humanize";
import { push } from "connected-react-router";
import MaterialTable from "@material-table/core";
import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./App";
import { CategoryAvatar, CurrencyDisplay } from "./DisplayElements";
import tableIcons from "./table_icons";
import Title from "./Title";

import Chart from "./Chart";

function Summary({ snapshots, globalSummary, currency }) {
  const classes = useStyles();

  const previousSample = snapshots?.length
    ? snapshots[snapshots.length - 1].balances.filter(
        (c) => c.code === currency,
      )[0].amount
    : null;
  const currentNav = globalSummary.filter((b) => b.code === currency)[0].amount;

  return (
    <>
      <Title>Total net worth</Title>
      <CurrencyDisplay
        displayZero
        component="p"
        variant="h4"
        code={currency}
        amount={currentNav}
      />

      <Typography color="textSecondary" className={classes.depositContext}>
        on {new Date().toDateString()}
      </Typography>
      {previousSample !== null && (
        <>
          <CurrencyDisplay
            displayZero
            delta
            component="p"
            variant="h6"
            code={currency}
            amount={currentNav - previousSample}
          />
          <Typography color="textSecondary" className={classes.depositContext}>
            since{" "}
            {humanize.relativeTime(
              Date.parse(snapshots[snapshots.length - 1].timestamp) / 1000,
            )}
          </Typography>
        </>
      )}
    </>
  );
}

function CategorySummary({ categorySummary, currency, push }) {
  const categories = React.useMemo(
    () => Object.keys(categorySummary),
    [categorySummary],
  );
  const categoryRender = React.useCallback(
    (rowData) => (
      <CategoryAvatar
        showLabel
        allCategories={categories}
        category={rowData.category}
      />
    ),
    [categories],
  );

  const balanceRender = React.useCallback(
    (rowData) => <CurrencyDisplay code={currency} amount={rowData.balance} />,
    [currency],
  );

  return (
    <MaterialTable
      data={Object.entries(categorySummary).map(([category, balance]) => ({
        id: category,
        category,
        balance: balance.filter((b) => b.code === currency)[0].amount,
      }))}
      icons={tableIcons}
      columns={[
        {
          id: "category",
          title: "Category",
          field: "category",
          render: categoryRender,
        },
        {
          id: "balance",
          title: "Balance",
          field: "balance",
          type: "numeric",
          render: balanceRender,
        },
      ]}
      onRowClick={(event, rowData) =>
        push(`/categories/${encodeURIComponent(rowData.category)}`)
      }
      options={{
        paging: false,
        search: false,
        actionsColumnIndex: -1,
        toolbar: false,
      }}
      components={{
        Container: ({ children }) => <React.Fragment children={children} />,
      }}
    />
  );
}

function CodeSummary({ codeSummary, currency }) {
  const balanceRender = React.useCallback(
    (rowData) => <CurrencyDisplay code={currency} amount={rowData.balance} />,
    [currency],
  );

  return (
    <MaterialTable
      data={Object.entries(codeSummary).map(([code, balance]) => ({
        id: code,
        code,
        balance: balance.filter((b) => b.code === currency)[0].amount,
      }))}
      icons={tableIcons}
      columns={[
        {
          id: "code",
          title: "Code",
          field: "code",
        },
        {
          id: "balance",
          title: "Balance",
          field: "balance",
          type: "numeric",
          render: balanceRender,
        },
      ]}
      options={{
        paging: false,
        search: false,
        actionsColumnIndex: -1,
        toolbar: false,
      }}
      components={{
        Container: ({ children }) => <React.Fragment children={children} />,
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  tallies: state.tallies,
  snapshots: state.snapshots.snapshotsData,
  activeCurrency: state.activeCurrency,
});

function Dashboard({ loadData, activeCurrency, snapshots, tallies, push }) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {tallies ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Summary
                  snapshots={snapshots?.global}
                  globalSummary={tallies.globalSummary}
                  currency={activeCurrency}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              {snapshots ? (
                <Paper className={classes.unpaddedPaper}>
                  <Chart
                    snapshots={snapshots?.global}
                    currency={activeCurrency}
                    currentValue={tallies.globalSummary}
                  />
                </Paper>
              ) : (
                <Paper className={classes.centerPaper}>
                  <CircularProgress />
                </Paper>
              )}
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.unpaddedPaper}>
                <Box className={classes.paper}>
                  <Title>Categories</Title>
                </Box>
                <CategorySummary
                  categorySummary={tallies.categorySummary}
                  currency={activeCurrency}
                  push={push}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.unpaddedPaper}>
                <Box className={classes.paper}>
                  <Title>Per code</Title>
                </Box>
                <CodeSummary
                  codeSummary={tallies.codeSummary}
                  currency={activeCurrency}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Paper className={classes.centerPaper}>
            <CircularProgress />
          </Paper>
        )}
      </Container>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
