import jwt_decode from "jwt-decode";

export const Auth = {
  setToken: (token, rememberMe) => {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");
    if (rememberMe) {
      localStorage.setItem("access_token", token);
    } else {
      sessionStorage.setItem("access_token", token);
    }
  },
  setTemporaryToken: (token) => {
    sessionStorage.setItem("access_token", token);
  },
  isAdmin: () => {
    const token =
      sessionStorage.getItem("access_token") ||
      localStorage.getItem("access_token");
    if (token === null) {
      return false;
    }

    try {
      const decoded = jwt_decode(token);
      return decoded.scopes.includes("admin");
    } catch {
      return false;
    }
  },
  signOut: () => {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");
  },
  hasCredentials: () =>
    sessionStorage.getItem("access_token") !== null ||
    localStorage.getItem("access_token") !== null,
  getJwtToken: () =>
    sessionStorage.getItem("access_token") ||
    localStorage.getItem("access_token"),
};
