import React from "react";
import { Avatar, Typography, Grid } from "@material-ui/core";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import * as colors from "@material-ui/core/colors";

const EPS = 0.00001;

const formatCurrency = (amount, code) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: code,
    }).format(Math.abs(amount) < EPS ? 0 : amount);
  } catch {
    return new Intl.NumberFormat("en-US").format(
      Math.abs(amount) < EPS ? 0 : amount,
    );
  }
};

export function CurrencyDisplay({
  code,
  amount,
  displayZero = false,
  delta = false,
  variant = "body2",
  iconSize,
  component = "span",
  style = {},
}) {
  if (!displayZero && Math.abs(amount) < EPS) return null;
  const color =
    Math.abs(amount) < EPS
      ? null
      : amount < 0
      ? colors.red[500]
      : colors.green[500];

  const icon =
    Math.abs(amount) < EPS ? null : amount < 0 ? (
      <DownIcon style={{ fontSize: iconSize, verticalAlign: "text-bottom" }} />
    ) : (
      <UpIcon style={{ fontSize: iconSize, verticalAlign: "text-bottom" }} />
    );

  return (
    <Typography
      component={component}
      variant={variant}
      noWrap
      style={{ verticalAlign: "center", color, ...style }}
    >
      {delta ? icon : null}
      {formatCurrency(amount, code)}
    </Typography>
  );
}

const hashCode = function (s) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const pallete = [
  colors.amber,
  colors.blue,
  colors.blueGrey,
  colors.brown,
  colors.common,
  colors.cyan,
  colors.deepOrange,
  colors.deepPurple,
  colors.green,
  colors.grey,
  colors.indigo,
  colors.lightBlue,
  colors.lightGreen,
  colors.lime,
  colors.orange,
  colors.pink,
  colors.purple,
  colors.red,
  colors.teal,
  colors.yellow,
];
export function CategoryAvatar({ category, showLabel }) {
  const empty = !!(!category || category === "");

  const colorIndex = hashCode(category);
  const backgroundColor = empty
    ? null
    : pallete[
        ((colorIndex % pallete.length) + pallete.length) % pallete.length
      ][500];
  const label = empty ? "" : category.substring(0, 2);

  return showLabel ? (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Avatar
          style={{ fontFamily: "dosis", fontWeight: "bold", backgroundColor }}
        >
          {label}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography style={{ fontStyle: category ? null : "italic" }} noWrap>
          {category || "No category"}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Avatar
      style={{ fontFamily: "dosis", fontWeight: "bold", backgroundColor }}
    >
      {label}
    </Avatar>
  );
}
