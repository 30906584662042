const readJsonFile = (file) => {
  var result = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
    reader.readAsText(file);
  }).then((text) => JSON.parse(text));

  return result;
};

export default readJsonFile;
