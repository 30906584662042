import React from "react";
import {
  Container,
  Paper,
  CircularProgress,
  Divider,
  Box,
  ListItemSecondaryAction,
  Hidden,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { SuspendedMaterialTable } from "./suspend";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Title from "./Title";
import { useStyles } from "./App";

import tableIcons from "./table_icons";

import { CurrencyDisplay, CategoryAvatar } from "./DisplayElements";

const trueFn = () => true;

function AccountTable({
  activeCurrency,
  accountData,
  snapshots,
  accountSummary,
  dispatchAsync,
  push,
}) {
  const classes = useStyles();

  const prepareRecord = (row) => {
    const hasSummary = accountSummary && accountSummary[row.account_id];
    const hasSnapshots =
      snapshots &&
      snapshots[`account:${row.account_id}`] &&
      snapshots[`account:${row.account_id}`].length;

    const balance = hasSummary
      ? accountSummary[row.account_id].filter(
          (b) => b.code === activeCurrency,
        )[0].amount
      : null;

    const delta =
      hasSummary && hasSnapshots
        ? balance -
          snapshots[`account:${row.account_id}`][
            snapshots[`account:${row.account_id}`].length - 1
          ].balances.filter((c) => c.code === activeCurrency)[0].amount
        : null;

    return {
      id: row.account_id,
      ...row,
      balance,
      delta,
    };
  };

  const unPrepareRecord = (row) => {
    return {
      ...row,
    };
  };

  const handleDelete = (oldData) =>
    dispatchAsync({
      type: "ACCOUNT_REMOVE",
      account: unPrepareRecord(oldData),
    });
  const handleAdd = (newData) =>
    dispatchAsync({ type: "ACCOUNT_ADD", account: unPrepareRecord(newData) });
  const handleUpdate = (newData, oldData) =>
    dispatchAsync({
      type: "ACCOUNT_UPDATE",
      account: unPrepareRecord(newData),
    });

  const categories = React.useMemo(
    () => (accountData ? [...new Set(accountData.map((b) => b.category))] : []),
    [accountData],
  );
  const categoryRender = React.useCallback(
    (rowData) => (
      <CategoryAvatar allCategories={categories} category={rowData.category} />
    ),
    [categories],
  );

  const balanceRender = React.useCallback(
    (rowData) =>
      rowData && rowData.balance !== null ? (
        <CurrencyDisplay
          code={activeCurrency}
          displayZero
          amount={rowData.balance}
        />
      ) : (
        <CircularProgress />
      ),
    [activeCurrency],
  );

  const changeRender = React.useCallback(
    (rowData) =>
      rowData && rowData.delta !== null ? (
        <CurrencyDisplay
          code={activeCurrency}
          delta
          displayZero
          amount={rowData.delta}
        />
      ) : (
        <CircularProgress />
      ),
    [activeCurrency],
  );

  if (!accountData) {
    return (
      <Paper className={classes.centerPaper}>
        <CircularProgress />
      </Paper>
    );
  }

  const columns = [
    {
      id: "category",
      title: "Category",
      field: "category",
      render: categoryRender,
    },
    {
      id: "title",
      title: "Title",
      field: "title",
    },
    {
      id: "balance",
      title: "Balance",
      field: `balance`,
      type: "numeric",
      editable: "never",
      render: balanceRender,
    },
    {
      id: "change",
      title: "Change",
      field: `delta`,
      type: "numeric",
      editable: "never",
      render: changeRender,
    },
    {
      id: "hide_from_global",
      title: "Hide from total",
      field: `hide_from_global`,
      type: "boolean",
      initialEditValue: false,
    },
  ];

  const tableData = accountData.map((row) => prepareRecord(row));

  return (
    <>
      <SuspendedMaterialTable
        editable={{
          isEditable: trueFn,
          isDeletable: trueFn,
          onRowAdd: handleAdd,
          onRowUpdate: handleUpdate,
          onRowDelete: handleDelete,
        }}
        onRowClick={(event, rowData) => push(`/accounts/${rowData.account_id}`)}
        data={tableData}
        columns={columns}
        icons={tableIcons}
        options={{
          paging: false,
          actionsColumnIndex: -1,
          search: true,
          addRowPosition: "first",
        }}
        title={<Title component="body2">Accounts</Title>}
      />
    </>
  );
}

export function AlternativeAccountTable({
  activeCurrency,
  accountData,
  snapshots,
  accountSummary,
  push,
}) {
  const classes = useStyles();

  const prepareRecord = (row) => {
    const hasSummary = accountSummary && accountSummary[row.account_id];
    const hasSnapshots =
      snapshots &&
      snapshots[`account:${row.account_id}`] &&
      snapshots[`account:${row.account_id}`].length;

    const balance = hasSummary
      ? accountSummary[row.account_id].filter(
          (b) => b.code === activeCurrency,
        )[0].amount
      : null;

    const delta =
      hasSummary && hasSnapshots
        ? balance -
          snapshots[`account:${row.account_id}`][
            snapshots[`account:${row.account_id}`].length - 1
          ].balances.filter((c) => c.code === activeCurrency)[0].amount
        : null;

    return {
      ...row,
      balance,
      delta,
    };
  };

  if (!accountData) {
    return (
      <Paper className={classes.centerPaper}>
        <CircularProgress />
      </Paper>
    );
  }

  const categories = [...new Set(accountData.map((b) => b.category))];
  const tableData = accountData.map((row) => prepareRecord(row));

  return (
    <Paper className={classes.unpaddedPaper}>
      <Box className={classes.paper}>
        <Title>Accounts</Title>
      </Box>
      <List className={classes.accountList}>
        {tableData.map((item) => (
          <React.Fragment key={item.account_id}>
            <Divider />
            <ListItem
              button
              onClick={() => push(`/accounts/${item.account_id}`)}
            >
              <ListItemAvatar>
                <Avatar>
                  <CategoryAvatar
                    allCategories={categories}
                    category={item.category}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={
                  item && item.delta !== null ? (
                    <CurrencyDisplay
                      code={activeCurrency}
                      iconSize={18}
                      delta
                      displayZero
                      amount={item.delta}
                    />
                  ) : (
                    <CircularProgress size={15} />
                  )
                }
              />
              <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                {item && item.balance !== null ? (
                  <CurrencyDisplay
                    variant="body1"
                    code={activeCurrency}
                    displayZero
                    amount={item.balance}
                  />
                ) : (
                  <CircularProgress />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
}

function Accounts(props) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Hidden smDown>
          <AccountTable {...props} />
        </Hidden>
        <Hidden mdUp>
          <AlternativeAccountTable {...props} />
        </Hidden>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  accountData: state.accounts.accountData
    ? Object.values(state.accounts.accountData)
    : null,
  accountSummary: state.tallies && state.tallies.accountSummary,
  snapshots: state.snapshots.snapshotsData,
  activeCurrency: state.activeCurrency,
});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  dispatchAsync: (action) =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject });
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
