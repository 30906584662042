import {
  call,
  put,
  takeEvery,
  takeLatest,
  fork,
  all,
  cancel,
  select,
  delay,
  take,
  cancelled,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import { eventChannel } from "redux-saga";
import { Auth } from "../lib/auth";
import { saveAs } from "file-saver";
import {
  login,
  UnauthorizedError,
  handleFetchAccounts,
  handleFetchRates,
  handleFetchUser,
  handleFetchSnapshots,
  handleFetchTallies,
  handleAddAccount,
  handleUpdateRates,
  handleRefreshRates,
  handleDeleteRate,
  handleUserExport,
  handleUserImport,
  handleUserReset,
  handleRemoveAccount,
  handleUpdateAccount,
  handleUpdatePreferences,
  handleChangePassword,
} from "../api";

import readJsonFile from "../lib/readJsonFile";

const CLOSED = "closed";

export function* socketSaga(url) {
  while (true) {
    console.log("Connecting to websocket...");
    const token = Auth.getJwtToken();

    const chan = eventChannel((emit) => {
      const ws = new WebSocket(`${url}?auth_token=${token}`);
      ws.onmessage = (e) => emit(JSON.parse(e.data));
      ws.onclose = (e) => emit(CLOSED);
      return () => {
        ws.close();
      };
    });

    try {
      while (true) {
        const data = yield take(chan);
        if (data === CLOSED) break;

        yield put({
          type: `SOCKET:${data.event.toUpperCase()}`,
          ...data.payload,
        });
      }
    } finally {
      if (yield cancelled()) {
        chan.close();
        break; // eslint-disable-line no-unsafe-finally
      }
    }

    yield delay(2000);
  }
}

function* refreshTallies() {
  const accounts = yield select((state) => state.accounts);
  const accountData = Object.values(accounts.accountData);

  const tallies = yield call(handleFetchTallies, accountData);

  yield put({ type: "TALLIES_RECEIVED", ...tallies, replace: true });
}

const handleInputError = (saga) =>
  function* ({ resolve, reject, ...props }) {
    try {
      yield call(saga, { ...props });
      resolve();
    } catch (error) {
      reject(error);

      if (error instanceof UnauthorizedError) {
        yield put({ type: "LOGOUT" });
      } else {
        yield put({
          type: "SHOW_ERROR",
          level: "error",
          message: error.message,
        });
      }
    }
  };

const handleError = (saga) =>
  function* (action) {
    try {
      yield call(saga, action);
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        yield put({ type: "LOGOUT" });
      } else {
        yield put({
          type: "SHOW_ERROR",
          level: "error",
          message: error.message,
        });
      }
    }
  };

function* initAdminState(action) {
  const ratesData = yield call(handleFetchRates);
  yield put({ type: "RATES_RECEIVED", ratesData });
}

function* initState(action) {
  const [accountData, user] = yield all([
    call(handleFetchAccounts),
    call(handleFetchUser),
  ]);
  yield put({ type: "ACCOUNTS_RECEIVED", accountData });
  yield put({ type: "USER_RECEIVED", user });

  const tallies = yield call(handleFetchTallies, accountData);
  yield put({ type: "TALLIES_RECEIVED", ...tallies, replace: true });

  const snapshots = yield call(handleFetchSnapshots, accountData);
  yield put({ type: "SNAPSHOTS_RECEIVED", snapshots, replace: true });
}

function* refreshAccount(action) {
  const accounts = yield select((state) => state.accounts);
  const accountData = Object.values(accounts.accountData);

  // We do full-sync in case account was deleted to completely remove it since delta-sync is hard
  const tallies = yield call(
    handleFetchTallies,
    accountData,
    !action.account.deleted && [action.account.account_id],
  );

  yield put({ type: "TALLIES_RECEIVED", ...tallies, replace: !action.account });

  const snapshots = yield call(
    handleFetchSnapshots,
    accountData,
    !action.account.deleted && [action.account.account_id],
  );
  yield put({
    type: "SNAPSHOTS_RECEIVED",
    snapshots,
    replace: !action.account,
  });
}

function* addAccount(action) {
  const newAccountData = yield call(handleAddAccount, action.account);
  yield put({ type: "ACCOUNT_UPDATED", account: newAccountData });
}

function* removeAccount(action) {
  const newAccountData = yield call(handleRemoveAccount, action.account);
  yield put({ type: "ACCOUNT_UPDATED", account: newAccountData });
}

function* updateRate(action) {
  yield call(handleUpdateRates, { codes: action.rates });

  if (action.toastOnSuccess) {
    yield put({
      type: "SHOW_ERROR",
      level: "success",
      message: "Code successfully registered!",
    });
  }
}

function* refreshRates(action) {
  yield call(handleRefreshRates);
  yield put({
    type: "SHOW_ERROR",
    level: "success",
    message: "Rates successfully refreshed!",
  });
}

function* removeRate(action) {
  yield call(handleDeleteRate, action.id);
}

function* updateAccount(action) {
  const newAccountData = yield call(handleUpdateAccount, action.account);
  yield put({ type: "ACCOUNT_UPDATED", account: newAccountData });
}

function* updatePreferences(action) {
  const newPreferences = yield call(
    handleUpdatePreferences,
    action.preferences,
  );
  yield put({ type: "PREFERENCES_RECEIVED", preferences: newPreferences });
}

function* changePassword(action) {
  yield call(handleChangePassword, action.oldPassword, action.newPassword);

  yield put({
    type: "SHOW_ERROR",
    level: "success",
    message: "Password changed!",
  });
}

function* quickEditUpdateBalance(action) {
  const account = yield select(
    (state) => state.accounts.accountData[state.quickEdit.accountId],
  );
  const newAccount = {
    ...account,
    balances: [
      ...account.balances.filter((b) => b !== action.balance),
      { code: action.balance.code, amount: action.newAmount },
    ],
  };
  try {
    const newAccountData = yield call(handleUpdateAccount, newAccount);
    yield put({ type: "ACCOUNT_UPDATED", account: newAccountData });
  } finally {
    yield put({ type: "QUICKEDIT_CLOSE" });
  }
}

function* updateAccountFromServer(action) {
  yield put({ type: "ACCOUNT_UPDATED", account: action.account });
}

function* updatePreferencesFromServer(action) {
  yield put({ type: "PREFERENCES_RECEIVED", preferences: action.preferences });
}

function* userExport(action) {
  const userExport = yield call(handleUserExport);
  saveAs(userExport, "export.json");
}

function* userImport(action) {
  const file = yield call(readJsonFile, action.payload);
  yield call(handleUserImport, file);

  yield put({
    type: "SHOW_ERROR",
    level: "success",
    message: "Import succeeded",
  });
}

function* userReset(action) {
  yield call(handleUserReset);

  yield put({
    type: "SHOW_ERROR",
    level: "success",
    message: "Reset successful.",
  });
}

function* redirectIfAccountDeleted(action) {
  const selector = (state) => {
    const location = state.router.location.pathname.split("/").slice(1);
    const { accountData } = state.accounts;
    return (
      location.length >= 2 &&
      location[0] === "accounts" &&
      accountData &&
      !accountData[location[1]]
    );
  };

  if (yield select(selector)) {
    yield put(push("/"));
  }
}

function* loginSessionSaga() {
  while (yield take("LOGGED_IN")) {
    yield put({ type: "ACCOUNTS_INIT" });

    const wsTask = yield fork(socketSaga, process.env.REACT_APP_HORIAMI_WS_URI);

    yield take("LOGOUT");
    yield call(() => Auth.signOut());

    yield cancel(wsTask);

    yield put(push("/"));
  }
}

function* handleRatesUpdated() {
  if (Auth.isAdmin()) {
    yield put({ type: "ADMIN_INIT" });
  }

  yield refreshTallies();
}

function* initialLoginSaga() {
  const token = Auth.getJwtToken();
  if (token) {
    const claims = JSON.parse(atob(token.split(".")[1]));

    yield put({ type: "LOGGED_IN", username: claims.sub });
    if (claims.scopes.includes("admin")) {
      yield put({ type: "RELOGGED_IN" });
    }
  }
}

function* loginSaga(action) {
  const accessToken = yield call(
    login,
    action.username,
    action.password,
    action.recaptchaToken,
  );
  Auth.setToken(accessToken, action.rememberMe);
  yield put({ type: "LOGGED_IN", username: action.username });
}

function* relogin(action) {
  const accessToken = yield call(
    login,
    action.username,
    action.password,
    action.recaptchaToken,
    "read write admin",
  );
  Auth.setTemporaryToken(accessToken);
  yield put({ type: "RELOGGED_IN" });
}

function* handleRellogedIn(action) {
  yield put({ type: "ADMIN_INIT" });
}

export default function* rootSaga() {
  yield fork(initialLoginSaga);
  yield fork(loginSessionSaga);

  yield all([
    takeLatest("SUBMIT_LOGIN", handleError(loginSaga)),
    takeLatest("ACCOUNTS_INIT", handleError(initState)),
    takeLatest("ADMIN_INIT", handleError(initAdminState)),
    takeLatest("RELOGGED_IN", handleRellogedIn),
    takeEvery("*", redirectIfAccountDeleted),
    takeLatest("SUBMIT_RELOGIN", handleError(relogin)),
    takeEvery("ACCOUNT_ADD", handleInputError(addAccount)),
    takeEvery("ACCOUNT_REMOVE", handleInputError(removeAccount)),
    takeEvery("RATE_UPDATE", handleInputError(updateRate)),
    takeEvery("RATE_REMOVE", handleInputError(removeRate)),
    takeEvery("RATE_REFRESH", handleInputError(refreshRates)),
    takeEvery("ACCOUNT_UPDATE", handleInputError(updateAccount)),
    takeEvery("ACCOUNT_UPDATE_ASYNC", handleError(updateAccount)),
    takeEvery("PREFERENCES_UPDATE", handleInputError(updatePreferences)),
    takeEvery("CHANGE_PASSWORD", handleInputError(changePassword)),
    takeEvery("QUICKEDIT_UPDATE_BALANCE", handleError(quickEditUpdateBalance)),
    takeEvery("ACCOUNT_UPDATED", handleError(refreshAccount)),
    takeEvery("USER_EXPORT", handleError(userExport)),
    takeEvery("USER_IMPORT", handleError(userImport)),
    takeEvery("USER_RESET", handleError(userReset)),
    takeLatest("SOCKET:RATES_UPDATED", handleError(handleRatesUpdated)),
    takeLatest("SOCKET:ACCOUNT_MODIFIED", handleError(updateAccountFromServer)),
    takeLatest(
      "SOCKET:PREFERENCES_MODIFIED",
      handleError(updatePreferencesFromServer),
    ),
    takeLatest("SOCKET:ACCOUNTS_MODIFIED", handleError(initState)),
  ]);
}
