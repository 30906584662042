import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./App";
import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const CurrencyLabel = ({ children }) => (
  <Typography component="span" variant="h6">
    {children}
  </Typography>
);

const CurrencyPicker = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={value}
      onChange={(_, value) => onChange(value)}
      size="small"
      exclusive
      className={classes.currencyPicker}
    >
      <ToggleButton value="EUR">
        <CurrencyLabel>€</CurrencyLabel>
      </ToggleButton>
      <ToggleButton value="USD">
        <CurrencyLabel>$</CurrencyLabel>
      </ToggleButton>
      <ToggleButton value="GBP">
        <CurrencyLabel>£</CurrencyLabel>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const mapStateToProps = (state) => ({
  value: state.activeCurrency,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (currency) => dispatch({ type: "SET_ACTIVE_CURRENCY", currency }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyPicker);
