import {
  Box,
  Switch,
  FormControlLabel,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { AreaChart } from "react-chartkick";
import { connect } from "react-redux";
import { useStyles } from "./App";

function Chart({
  snapshots,
  currency,
  deltaDisplay,
  timeSpan,
  currentValue,
  onDeltaDisplayChange,
  onTimeSpanChange,
}) {
  const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let timeDelta;
  switch (timeSpan) {
    case "weekly":
      timeDelta = 1000 * 3600 * 24 * 7;
      break;
    case "monthly":
      timeDelta = 1000 * 3600 * 24 * 31;
      break;
    case "bi-monthly":
      timeDelta = 1000 * 3600 * 24 * 31 * 2;
      break;
    case "hexa-monthly":
      timeDelta = 1000 * 3600 * 24 * 31 * 6;
      break;
    case "yearly":
      timeDelta = 1000 * 3600 * 24 * 365;
      break;
    case "bi-yearly":
      timeDelta = 1000 * 3600 * 24 * 365 * 2;
      break;
    default:
      timeDelta = Infinity;
  }
  const utcNow = new Date();
  let filteredDataPoints = snapshots
    .concat(
      currentValue
        ? [{ timestamp: new Date().toUTCString(), balances: currentValue }]
        : [],
    )
    .map((snapshot) => ({
      timestamp: new Date(Date.parse(snapshot.timestamp)),
      value: parseFloat(
        snapshot.balances.filter((b) => b.code === currency)[0].amount,
      ),
    }))
    .filter(({ timestamp }) => utcNow - timestamp < timeDelta)
    .sort((a, b) => a.timestamp - b.timestamp);
  if (deltaDisplay && filteredDataPoints.length > 1)
    filteredDataPoints = filteredDataPoints.map(({ timestamp, value }) => ({
      timestamp,
      value:
        (100 * (value - filteredDataPoints[0].value)) /
        filteredDataPoints[0].value,
    }));
  const timeSeries = Object.fromEntries(
    filteredDataPoints.map(({ timestamp, value }) => [
      timestamp.toISOString().substring(0, 10),
      value,
    ]),
  );
  return (
    <>
      <Box className={classes.graphControls}>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={deltaDisplay}
                onChange={onDeltaDisplayChange}
                value="checkedB"
                color="primary"
              />
            }
            label="Percent change"
          />
        </FormControl>
        <FormControl>
          <Select value={timeSpan} onChange={onTimeSpanChange}>
            <MenuItem value="all_time">All time</MenuItem>
            <MenuItem value="weekly">Week</MenuItem>
            <MenuItem value="monthly">Month</MenuItem>
            <MenuItem value="bi-monthly">2 months</MenuItem>
            <MenuItem value="hexa-monthly">6 months</MenuItem>
            <MenuItem value="yearly">Year</MenuItem>
            <MenuItem value="bi-yearly">2 years</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Box className={classes.graphContainer}>
        <AreaChart
          min={null}
          thousands=","
          label="Value"
          data={timeSeries}
          points={Object.keys(timeSeries).length < 30 ? undefined : false}
        />
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  deltaDisplay: state.chartDisplay.deltaDisplay,
  timeSpan: state.chartDisplay.timeSpan,
});
const mapDispatchToProps = (dispatch) => ({
  onDeltaDisplayChange: (event, value) =>
    dispatch({ type: "CHART:DELTA_DISPLAY_CHANGE", value }),
  onTimeSpanChange: (event, value) =>
    dispatch({ type: "CHART:TIME_SPAN_CHANGE", value: value.props.value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
