import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { connect } from "react-redux";

import logo from "./assets/horiami.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(5),
    height: "7em",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (username, password, rememberMe, recaptchaToken, adminMode) =>
    dispatch({
      type: adminMode ? "SUBMIT_RELOGIN" : "SUBMIT_LOGIN",
      username,
      password,
      rememberMe,
      recaptchaToken,
    }),
});

const mapStateToProps = (state) => ({
  currentUsername: state.authState.username,
});

function SignIn({ submitLogin, currentUsername, adminMode = false }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();

  React.useEffect(() => {
    if (currentUsername) {
      setUsername(currentUsername);
    }
  }, [currentUsername]);

  const submitForm = async (e) => {
    e.preventDefault();
    const recaptchaToken = process.env.REACT_APP_RECAPTCHA_KEY
      ? await executeRecaptcha("login")
      : "";
    await submitLogin(
      username,
      password,
      rememberMe,
      recaptchaToken,
      adminMode,
    );
  };

  const submitEnabled =
    (process.env.REACT_APP_RECAPTCHA_KEY || executeRecaptcha) &&
    !!username &&
    !!password;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {!adminMode && (
          <>
            <img alt="Horiami" className={classes.avatar} src={logo} />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </>
        )}
        {adminMode && <>Please re-enter your password to enter admin mode.</>}
        <form className={classes.form} onSubmit={submitForm} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            disabled={adminMode}
            value={currentUsername || username}
            onChange={(event) => setUsername(event.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            autoComplete="current-password"
          />
          {!adminMode && (
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onClick={() => setRememberMe(!rememberMe)}
                />
              }
              label="Remember me"
            />
          )}
          <Button
            type="submit"
            fullWidth
            disabled={!submitEnabled}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {!adminMode && <>Sign In</>}
            {adminMode && <>Enter admin mode</>}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
