import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { push } from "connected-react-router";

import { useStyles } from "./App";
import SignIn from "./Login";
import tableIcons from "./table_icons";
import { SuspendedMaterialTable } from "./suspend";
import Title from "./Title";

const trueFn = () => true;

function RatesTable({ rates, dispatchAsync }) {
  const tableData = rates.map((rate, i) => ({
    id: rate.id,
    ...rate,
  }));

  const handleAdd = async (newData) => {
    await dispatchAsync({
      type: "RATE_UPDATE",
      rates: [
        {
          code: newData.from_code,
          target_code: newData.to_code,
          expression: newData.expression,
        },
      ],
    });
  };

  const handleUpdate = async (newData, oldData) => {
    await dispatchAsync({
      type: "RATE_UPDATE",
      rates: [
        {
          code: newData.from_code,
          target_code: newData.to_code,
          expression: newData.expression,
        },
      ],
    });
  };

  const handleDelete = async (oldData) => {
    await dispatchAsync({
      type: "RATE_REMOVE",
      id: oldData.id,
    });
  };

  const columns = [
    {
      title: "From code",
      field: "from_code",
      editable: "onAdd",
    },
    {
      title: "Type",
      field: "type",
      lookup: {
        0: "Unknown",
        1: "Currency",
        2: "Stock quote",
        3: "Synthetic",
      },
      initialEditValue: 3,
      editable: "never",
    },
    {
      title: "To code",
      field: "to_code",
      editable: "onAdd",
    },
    {
      title: "Expression",
      field: "expression",
    },
    {
      title: "Current value",
      field: "rate",
      type: "numeric",
      editable: "never",
    },
  ];

  return (
    <SuspendedMaterialTable
      editable={{
        isEditable: ({ type }) => type === 3,
        isDeletable: trueFn,
        onRowAdd: handleAdd,
        onRowUpdate: handleUpdate,
        onRowDelete: handleDelete,
      }}
      data={tableData}
      columns={columns}
      icons={tableIcons}
      options={{ paging: false, actionsColumnIndex: -1, search: true }}
      title={<Title>Rates</Title>}
    />
  );
}

function RateManagement({ dispatchAsync }) {
  const classes = useStyles();
  const [code, setCode] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const canSubmit = code.length > 0 && !submitting;

  const submitForm = async (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    try {
      await dispatchAsync({
        type: "RATE_UPDATE",
        rates: [
          {
            code,
          },
        ],
        toastOnSuccess: true,
      });
      setCode("");
    } catch {
      // ignored
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper className={classes.paper}>
        <Title>Register new rate</Title>
        <form className={classes.form} onSubmit={submitForm} noValidate>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="rate-code"
            label="Rate code"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSubmit}
            className={classes.submit}
          >
            Register code
          </Button>
        </form>
      </Paper>
    </Grid>
  );
}

function RefreshRates({ dispatchAsync }) {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);

  const submitForm = async () => {
    setSubmitting(true);
    try {
      await dispatchAsync({
        type: "RATE_REFRESH",
      });
    } catch {
      // ignored
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper className={classes.paper}>
        <Title>Rate management</Title>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={submitting}
          onClick={submitForm}
          className={classes.submit}
        >
          Refresh rates
        </Button>
      </Paper>
    </Grid>
  );
}

function Admin({ dispatchAsync, adminMode, rates }) {
  const classes = useStyles();

  if (!adminMode) {
    return <SignIn adminMode />;
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {rates && (
              <>
                <RatesTable rates={rates} dispatchAsync={dispatchAsync} />
              </>
            )}
          </Grid>
          <RateManagement dispatchAsync={dispatchAsync} />
          <RefreshRates dispatchAsync={dispatchAsync} />
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  adminMode: state.authState.adminMode,
  rates: state.rates.rates,
});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  dispatchAsync: (action) =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject });
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
