import React from "react";
import { Container, Paper, ListItemSecondaryAction } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import { red } from "@material-ui/core/colors";
import Title from "./Title";
import { useStyles } from "./App";

const DangerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

const ButtonListItem = withStyles(() => ({
  root: {
    paddingRight: "10em",
  },
}))(ListItem);

function parseCronspec(cronspec) {
  const date = new Date();
  const {
    groups: { hours, minutes },
  } = cronspec.match(/(?<minutes>[0-9]+) (?<hours>[0-9]+) .*/);
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date;
}

function Settings({ dispatchAsync, preferences }) {
  const classes = useStyles();
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");

  const handleDateChange = (date) => {
    const cronspec = `${date.getMinutes()} ${date.getHours()} * * *`;
    dispatchAsync({
      type: "PREFERENCES_UPDATE",
      preferences: {
        ...preferences,
        snapshot_cron: cronspec,
      },
    });
  };

  const handleClickOpen = () => {
    setResetDialogOpen(true);
  };

  const closeCancel = () => {
    setResetDialogOpen(false);
  };

  const closeReset = () => {
    setResetDialogOpen(false);
    dispatchAsync({ type: "USER_RESET" });
  };

  const changePasswordEnabled =
    !!oldPassword &&
    !!newPassword &&
    newPassword !== oldPassword &&
    newPassword === newPasswordConfirm;

  const passwordMismatch =
    !!newPassword && !!newPasswordConfirm && newPasswordConfirm !== newPassword;

  const passwordSame = !!newPassword && newPassword === oldPassword;

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      await dispatchAsync({
        type: "CHANGE_PASSWORD",
        oldPassword,
        newPassword,
      });

      // Clear the form on success
      setOldPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    } catch {
      // eslint: ignore
    }
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            {!preferences.snapshot_cron && (
              <Paper className={classes.centerPaper}>
                <CircularProgress />
              </Paper>
            )}
            {preferences.snapshot_cron && (
              <Paper className={classes.paper}>
                <Title>Preferences</Title>
                {preferences.snapshot_cron && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      className={classes.input}
                      margin="normal"
                      variant="outlined"
                      id="time-picker"
                      label="Snapshot time"
                      value={parseCronspec(preferences.snapshot_cron)}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Title>Change password</Title>
              <form className={classes.form} onSubmit={submitForm} noValidate>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  name="old-password"
                  label="Old password"
                  type="password"
                  id="old-password"
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                  autoComplete="current-password"
                />
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  name="new-password"
                  label="New password"
                  type="password"
                  id="new-password"
                  error={passwordSame}
                  helperText={
                    passwordSame && "New password is the same as the old one"
                  }
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm new password"
                  type="password"
                  error={passwordMismatch}
                  helperText={passwordMismatch && "Passwords do not match"}
                  id="new-password-confirm"
                  value={newPasswordConfirm}
                  onChange={(event) =>
                    setNewPasswordConfirm(event.target.value)
                  }
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!changePasswordEnabled}
                  className={classes.submit}
                >
                  Change password
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Title>Data management</Title>
              <List dense={false}>
                <ButtonListItem>
                  <ListItemText
                    primary="Export"
                    secondary="Export all the accounts and snapshots to a file"
                  />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() => dispatchAsync({ type: "USER_EXPORT" })}
                      variant="contained"
                      color="primary"
                    >
                      Export
                    </Button>
                  </ListItemSecondaryAction>
                </ButtonListItem>
                <ButtonListItem>
                  <ListItemText
                    primary="Import"
                    secondary="Import accounts and snapshots from a file"
                  />
                  <ListItemSecondaryAction>
                    <input
                      accept="application/json"
                      className={classes.hiddenInput}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(ev) =>
                        dispatchAsync({
                          type: "USER_IMPORT",
                          payload: ev.target.files[0],
                        })
                      }
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Import
                      </Button>
                    </label>
                  </ListItemSecondaryAction>
                </ButtonListItem>
                <Dialog
                  open={resetDialogOpen}
                  onClose={closeCancel}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Resetting will irreversibly remove all the accounts and
                      snapshots. Please export your data before proceeding to
                      avoid data loss.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeCancel} color="primary" autoFocus>
                      Cancel
                    </Button>
                    <Button onClick={closeReset} color="primary">
                      Reset account
                    </Button>
                  </DialogActions>
                </Dialog>
                <ButtonListItem>
                  <ListItemText
                    primary="Reset"
                    secondary="This will remove all the accounts and snapshots. This cannot be undone!"
                  />
                  <ListItemSecondaryAction>
                    <DangerButton variant="contained" onClick={handleClickOpen}>
                      Reset
                    </DangerButton>
                  </ListItemSecondaryAction>
                </ButtonListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  preferences: state.preferences,
});

const mapDispatchToProps = (dispatch) => ({
  push: (path) => dispatch(push(path)),
  dispatchAsync: (action) =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject });
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
