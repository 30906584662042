import React from "react";
import MaterialTable from "@material-table/core";

export const useSuspend = (callback, deps) => {
  const [suspended, setSuspended] = React.useState(false);
  const [state, setState] = React.useState(callback());

  React.useEffect(() => {
    if (!suspended) {
      setState(callback());
    }
  }, [suspended, ...deps]);

  return [state, setSuspended];
};

const MemoMaterialTable = React.memo(MaterialTable);

export const SuspendedMaterialTable = (props) => {
  const [suspendedProps, setEditing] = useSuspend(
    () => props,
    Object.values(props),
  );
  const finalProps = React.useMemo(() => {
    return {
      ...suspendedProps,
      editable: suspendedProps.editable && {
        ...suspendedProps.editable,
        onEditStateChange: setEditing,
      },
    };
  }, [suspendedProps]);

  return <MemoMaterialTable {...finalProps} />;
};
