import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import humanize from "humanize";
import { Box, Grid, Typography } from "@material-ui/core";

import clsx from "clsx";

import { connect } from "react-redux";
import Title from "./Title";

import { useStyles } from "./App";

import tableIcons from "./table_icons";
import { CurrencyDisplay } from "./DisplayElements";

import { SuspendedMaterialTable } from "./suspend";

import Chart from "./Chart";
import TextField from "@material-ui/core/TextField";

import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Link from "@material-ui/core/Link";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import { makeStyles } from "@material-ui/core/styles";

export const mdStyles = (theme) => ({
  "& img": {
    verticalAlign: "sub",
  },
  "& code": {
    fontFamily: "Roboto Mono",
  },
  "& pre": {
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    borderRadius: 4,
    padding: theme.spacing(1),
    overflow: "auto",
  },
  "& blockquote": {
    borderLeft: "2px solid",
    marginLeft: 0,
    paddingLeft: theme.spacing(1),
    borderColor: theme.palette.secondary.light,
  },
  "& table": {
    width: "max-content",
    maxWidth: "100%",
    overflow: "auto",
    borderSpacing: 0,
    borderCollapse: "collapse",
  },
  "& table tr": {
    borderTop: "1px solid #c6cbd1",
  },
  "& table tr td, & table tr th": {
    padding: "6px 13px",
    border: "1px solid #dfe2e5",
  },
  "& table th": {
    fontWeight: 600,
  },
  fontSize: theme.typography.fontSize,
  color: theme.palette.text.primary,
});

export const useMdStyles = makeStyles((theme) => ({
  table: {},
  description: {
    fontFamily: "Roboto Mono",
    fontSize: theme.typography.fontSize,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  markdown: {
    marginBottom: -theme.spacing(2),
    marginTop: -theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "& *:first-child": {
      marginTop: 0,
    },
    "& *:last-child": {
      marginBottom: 0,
    },
    ...mdStyles(theme),
    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      pointerEvents: "none",
      background: `linear-gradient(${theme.palette.background.paper} 0%, transparent 10%, transparent 90%, ${theme.palette.background.paper} 100%)`,
    },
    overflowX: "hidden",
    maxHeight: 400,
  },
}));

function Description({ editingEnabled, description, onChange }) {
  const classes = useMdStyles();

  const [localDescription, setLocalDescription] = React.useState(description);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    setLocalDescription(description);
  }, [description]);

  return (
    <>
      {editing && (
        <TextField
          fullWidth
          multiline
          label="Notes"
          variant="outlined"
          classes={{
            root: classes.container,
          }}
          value={localDescription}
          onChange={(evt) => setLocalDescription(evt.target.value)}
          onBlur={() => {
            const newDescription = localDescription.trim();
            setLocalDescription(newDescription);
            onChange(newDescription);
            setEditing(false);
          }}
          InputProps={{
            className: classes.description,
            autoFocus: true,
            onFocus: (evt) => evt.target.select(),
          }}
        />
      )}
      {!editing && (
        <TextField
          fullWidth
          variant="outlined"
          multiline
          label="Notes"
          value={localDescription}
          onClick={(evt) => {
            // So we can click on links in description
            if (editingEnabled && !evt.target.href) {
              setEditing(true);
            }
          }}
          classes={{
            root: classes.container,
          }}
          InputLabelProps={{ shrink: !!localDescription }}
          InputProps={{
            inputComponent: ReactMarkdown,
            inputProps: {
              remarkPlugins: [gfm],
              children: localDescription || "&nbsp;",
              linkTarget: "_blank",
              className: classes.markdown,
              components: {
                a: Link,
              },
            },
          }}
        />
      )}
    </>
  );
}

function Summary({ account, accountSummary, currency, snapshots }) {
  const classes = useStyles();

  const current_balance = accountSummary
    ? accountSummary.filter((c) => c.code === currency)[0].amount
    : null;
  const previousSample =
    snapshots && snapshots.length
      ? snapshots[snapshots.length - 1].balances.filter(
          (c) => c.code === currency,
        )[0].amount
      : null;

  return (
    <>
      <div className={classes.accountDetailsHeader}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          className={classes.title}
        >
          {account.title}
        </Typography>
      </div>
      {accountSummary ? (
        <CurrencyDisplay
          displayZero
          component="p"
          variant="h4"
          code={currency}
          amount={current_balance}
        />
      ) : (
        <CircularProgress />
      )}

      <Typography color="textSecondary" className={classes.depositContext}>
        on {new Date().toDateString()}
      </Typography>
      {previousSample && accountSummary ? (
        <>
          <CurrencyDisplay
            displayZero
            delta
            component="p"
            variant="h6"
            code={currency}
            amount={current_balance - previousSample}
          />
          <Typography color="textSecondary" className={classes.depositContext}>
            since{" "}
            {humanize.relativeTime(
              Date.parse(snapshots[snapshots.length - 1].timestamp) / 1000,
            )}
          </Typography>
        </>
      ) : null}
      {account.hide_from_global && (
        <Typography color="textSecondary">
          {" "}
          <VisibilityOff style={{ verticalAlign: "top" }} /> Hidden from total
          balance{" "}
        </Typography>
      )}
    </>
  );
}

const trueFn = () => true;

function AccountBalancesTable({ balances, codeSummary, onChange, currency }) {
  const tableData = balances.map((balance, i) => ({
    id: i,
    _ref: balance,
    ...balance,
    balance: (function () {
      return codeSummary && codeSummary[i]
        ? codeSummary[i].find((p) => p.code === currency).amount
        : null;
    })(),
  }));

  const handleAdd = async (newData) => {
    await onChange(
      [...tableData, newData].map((c) => ({ code: c.code, amount: c.amount })),
    );
  };

  const handleUpdate = async (newData, oldData) => {
    await onChange(
      tableData
        .map((b) => (b._ref === oldData._ref ? newData : b))
        .map((c) => ({ code: c.code, amount: c.amount })),
    );
  };

  const handleDelete = async (oldData) => {
    await onChange(
      tableData
        .filter((b) => b._ref !== oldData._ref)
        .map((c) => ({ code: c.code, amount: c.amount })),
    );
  };

  const balanceRender = React.useCallback(
    (rowData) =>
      rowData && rowData.balance !== null ? (
        <CurrencyDisplay code={currency} displayZero amount={rowData.balance} />
      ) : (
        <CircularProgress size={20} />
      ),
    [currency],
  );

  const columns = [
    { title: "Code", field: "code" },
    {
      title: "Amount",
      field: "amount",
      type: "numeric",
    },
    {
      title: "Balance",
      field: "balance",
      type: "numeric",
      editable: "never",
      validate: trueFn,
      render: balanceRender,
    },
  ];

  return (
    <SuspendedMaterialTable
      editable={{
        isEditable: trueFn,
        isDeletable: trueFn,
        onRowAdd: handleAdd,
        onRowUpdate: handleUpdate,
        onRowDelete: handleDelete,
      }}
      data={tableData}
      columns={columns}
      icons={tableIcons}
      options={{ paging: false, actionsColumnIndex: -1, search: false }}
      title={<Title>Balances</Title>}
    />
  );
}

function Management() {
  return <></>;
}

function AccountDetails({
  account,
  snapshots,
  activeCurrency,
  accountSummary,
  dispatch,
  codeSummary,
}) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const dispatchAsync = (action) =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject });
    });

  const handleUpdate = React.useCallback(
    (balances) =>
      dispatchAsync({
        type: "ACCOUNT_UPDATE",
        account: {
          ...account,
          balances,
        },
      }),
    [account, dispatchAsync],
  );

  if (!account) {
    return (
      <Paper className={classes.centerPaper}>
        <CircularProgress />
      </Paper>
    );
  }

  const updateDescription = (description) => {
    dispatch({
      type: "ACCOUNT_UPDATE_ASYNC",
      account: {
        ...account,
        description,
      },
    });
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Summary
              account={account}
              accountSummary={accountSummary}
              currency={activeCurrency}
              snapshots={snapshots}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.unpaddedPaper}>
            {snapshots && (
              <Chart snapshots={snapshots} currency={activeCurrency} />
            )}
            {!snapshots && (
              <Box className={classes.centerPaper}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {!codeSummary && (
            <Box className={classes.centerPaper}>
              <CircularProgress />
            </Box>
          )}
          {codeSummary && (
            <AccountBalancesTable
              balances={account.balances}
              currency={activeCurrency}
              codeSummary={codeSummary}
              onChange={handleUpdate}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Description
            editingEnabled
            onChange={updateDescription}
            description={account.description || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Management />
        </Grid>
      </Grid>
    </Container>
  );
}

function mapStateToProps(state, { match }) {
  const { account_id } = match.params;
  return {
    account:
      state.accounts.accountData && state.accounts.accountData[account_id],
    snapshots:
      state.snapshots.snapshotsData &&
      state.snapshots.snapshotsData[`account:${account_id}`],
    accountSummary: state.tallies && state.tallies.accountSummary[account_id],
    codeSummary: state.tallies && state.tallies.accountCodeSummary[account_id],
    activeCurrency: state.activeCurrency,
  };
}

export default connect(mapStateToProps)(AccountDetails);
