import { combineReducers } from "redux";
import { connectRouter, LOCATION_CHANGE } from "connected-react-router";

const mergeAccounts = (accountData, newAccount) => {
  if (!newAccount.deleted)
    return { ...accountData, [newAccount.account_id]: newAccount };

  const cloned = { ...accountData };
  delete cloned[newAccount.account_id];
  return cloned;
};

const merge = (oldCollection, newCollection, replace) => {
  if (replace || !oldCollection) return newCollection;

  const result = { ...oldCollection, ...newCollection };
  Object.entries(newCollection).forEach(([key, value]) => {
    if (!value) delete result[key];
  });
  return result;
};

function chartDisplay(
  state = { timeSpan: "all_time", deltaDisplay: false },
  action,
) {
  switch (action.type) {
    case "CHART:DELTA_DISPLAY_CHANGE":
      return { ...state, deltaDisplay: action.value };
    case "CHART:TIME_SPAN_CHANGE":
      return { ...state, timeSpan: action.value };
    default:
      return state;
  }
}

function authState(
  state = { loggedIn: false, isAdmin: false, adminMode: false },
  action,
) {
  switch (action.type) {
    case "USER_RECEIVED":
      return { ...state, isAdmin: action.user.groups.includes("admin") };
    case "LOGGED_IN":
      return { ...state, username: action.username, loggedIn: true };
    case "RELOGGED_IN":
      return { ...state, adminMode: true };
    case "LOGOUT":
      return { username: null, loggedIn: false, adminMode: false };
    default:
      return state;
  }
}

function tallies(state = null, action) {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "TALLIES_RECEIVED":
      return {
        accountSummary: merge(
          state && state.accountSummary,
          action.accountSummary,
          action.replace,
        ),
        accountCodeSummary: merge(
          state && state.accountCodeSummary,
          action.accountCodeSummary,
          action.replace,
        ),
        categorySummary: action.categorySummary,
        globalSummary: action.globalSummary,
        codeSummary: action.codeSummary,
      };
    default:
      return state;
  }
}

function snapshots(state = {}, action) {
  switch (action.type) {
    case "LOGOUT":
      return {};
    case "SNAPSHOTS_RECEIVED":
      return {
        ...state,
        // FIXME: account deletion
        snapshotsData: merge(
          state.snapshotsData,
          action.snapshots,
          action.replace,
        ),
      };
    default:
      return state;
  }
}

function preferences(state = {}, action) {
  switch (action.type) {
    case "LOGOUT":
      return {};
    case "USER_RECEIVED":
      return { ...action.user.preferences };
    case "PREFERENCES_RECEIVED":
      return { ...action.preferences };
    default:
      return state;
  }
}

function rates(state = {}, action) {
  switch (action.type) {
    case "LOGOUT":
      return {};
    case "RATES_RECEIVED":
      return { rates: action.ratesData };
    default:
      return state;
  }
}

function accounts(state = {}, action) {
  switch (action.type) {
    case "LOGOUT":
      return {};
    case "ACCOUNT_UPDATED": {
      const shouldIgnore =
        (!state.accountData[action.account.account_id] &&
          action.account.deleted) ||
        (state.accountData[action.account.account_id] &&
          state.accountData[action.account.account_id].version >=
            action.account.version);

      return shouldIgnore
        ? state
        : {
            ...state,
            accountData: mergeAccounts(state.accountData, action.account),
          };
    }
    case "ACCOUNTS_RECEIVED":
      return {
        ...state,
        accountData: Object.fromEntries(
          action.accountData.map((account) => [account.account_id, account]),
        ),
      };
    default:
      return state;
  }
}

function quickEdit(state = { open: false }, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
    case "QUICKEDIT_CLOSE":
      return { open: false };
    case "QUICKEDIT_OPEN":
      return {
        open: true,
        accountId: action.accountId,
        balance: action.balance,
      };
    default:
      return state;
  }
}

function app(state = { drawerOpen: false }, action) {
  switch (action.type) {
    case "LOGOUT":
    case LOCATION_CHANGE:
    case "DRAWER_CLOSE":
      return { ...state, drawerOpen: false };
    case "DRAWER_OPEN":
      return { ...state, drawerOpen: true };
    default:
      return state;
  }
}

function activeCurrency(state = "EUR", action) {
  switch (action.type) {
    case "SET_ACTIVE_CURRENCY":
      return action.currency || state;
    default:
      return state;
  }
}

function error(state = null, action) {
  switch (action.type) {
    case "SHOW_ERROR":
      return { ...action };
    default:
      return state;
  }
}

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    quickEdit,
    preferences,
    accounts,
    activeCurrency,
    tallies,
    snapshots,
    chartDisplay,
    rates,
    error,
    app,
    authState,
  });

export default createRootReducer;
