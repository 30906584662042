import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewListIcon from "@material-ui/icons/ViewList";
import SettingsIcon from "@material-ui/icons/Settings";
import SecurityIcon from "@material-ui/icons/Security";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useStyles } from "./App";
import { CurrencyDisplay } from "./DisplayElements";

const Navigation = ({
  path,
  navigate,
  username,
  logout,
  accounts,
  isAdmin,
  displayQuickedit,
  accountCodeSummary,
}) => {
  const classes = useStyles();
  const navigateProps = (target) => ({
    selected: path === target,
    onClick: () => navigate(target),
  });

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <div>
          <ListItem {...navigateProps("/")} button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem {...navigateProps("/accounts")} button>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListSubheader inset>
            Logged in as <b>{username}</b>
          </ListSubheader>
          <ListItem {...navigateProps("/settings")} button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </div>
      </List>
      {isAdmin && (
        <>
          <Divider />
          <List>
            <div>
              <ListItem {...navigateProps("/admin")} button>
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            </div>
          </List>
        </>
      )}
      {accounts && accountCodeSummary && (
        <>
          <Divider />
          <List>
            <div>
              <ListSubheader inset>Quick edit</ListSubheader>
              {Object.keys(accounts)
                .filter((acc) => !accounts[acc].hide_from_global)
                .flatMap((acc) =>
                  accounts[acc].balances.map((balance, i) => ({
                    accountId: acc,
                    value: accountCodeSummary?.[acc]?.[i]?.[0]?.amount || 0,
                    balance,
                  })),
                )
                .sort((a, b) => b.value - a.value)
                .slice(0, 5)
                .map(({ accountId, balance }, i) => (
                  <ListItem
                    key={i}
                    button
                    onClick={() => displayQuickedit(accountId, balance)}
                  >
                    <ListItemText
                      primary={accounts[accountId].title}
                      secondary={balance.code}
                    />
                    <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                      <CurrencyDisplay
                        displayZero
                        component="p"
                        code={balance.code}
                        amount={balance.amount}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </div>
          </List>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
  username: state.authState.username,
  isAdmin: state.authState.isAdmin,
  accounts: state.accounts.accountData,
  accountCodeSummary: state.tallies && state.tallies.accountCodeSummary,
});

const mapDispatchToProps = (dispatch) => ({
  navigate: (path) => dispatch(push(path)),
  displayQuickedit: (accountId, balance) =>
    dispatch({ type: "QUICKEDIT_OPEN", accountId, balance }),
  logout: () => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
