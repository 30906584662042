import React from "react";
import { createRoot } from "react-dom/client";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

import { Provider, connect } from "react-redux";

import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import rootSaga from "./sagas";
import rootReducer from "./reducers";
import Login from "./Login";
import App from "./App";
import theme from "./theme";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import "typeface-dosis";
import "typeface-roboto-mono";

import { SnackbarProvider, withSnackbar } from "notistack";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const ErrorHandlerX = ({ error, enqueueSnackbar }) => {
  React.useEffect(() => {
    if (error) enqueueSnackbar(error.message, { variant: error.level });
  }, [error, enqueueSnackbar]);

  return null;
};

const mapStateToPropsX = (state) => ({
  error: state.error,
});

const ErrorHandler = withSnackbar(connect(mapStateToPropsX)(ErrorHandlerX));

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger());
}

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(rootSaga);

const Horiami = ({ loggedIn }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // You can use useEffect to trigger the verification as soon as the component being loaded
  React.useEffect(() => {
    if (executeRecaptcha) {
      executeRecaptcha("homepage");
    }
  }, [executeRecaptcha]);

  return (
    <>
      <SnackbarProvider>
        <ErrorHandler />
        {loggedIn && <App />}
        {!loggedIn && <Login />}
      </SnackbarProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.authState.loggedIn,
});

const root = createRoot(document.querySelector("#root"));

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          language="en"
        >
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {React.createElement(connect(mapStateToProps)(Horiami))}
        </GoogleReCaptchaProvider>
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
);

serviceWorkerRegistration.register();
reportWebVitals();
